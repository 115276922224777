import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebaseConfig.js';
import './AdminProperties.css';

const AdminPropertiesIA = ({ properties }) => {
  const [sortedProperties, setSortedProperties] = useState(properties);
  const [sortOrder, setSortOrder] = useState('asc'); // Estado para manejar el orden de la lista
  const [allProperties, setAllProperties] = useState([]); // Renombrado aquí
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 5;

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [editForm, setEditForm] = useState({
    direccion: '',
    publicacion: '',
    archivos: []
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bajaReason, setBajaReason] = useState('');
  const [salePrice, setSalePrice] = useState('');

  useEffect(() => {
    setSortedProperties(properties);
    setAllProperties(properties);
    setLoading(false); // Simulación de carga
  }, [properties]);

  // Función para manejar la lógica de ordenamiento
  const handleSort = (field) => {
    const sorted = [...sortedProperties].sort((a, b) => {
      if (field === 'precio') {
        return sortOrder === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else {
        return sortOrder === 'asc' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
      }
    });
    setSortedProperties(sorted);
    setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    setFilteredProperties(
      allProperties.filter(property =>
        (property.direccion?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (property.descrip?.toLowerCase().includes(searchTerm.toLowerCase()) || '')
      )
    );
  }, [searchTerm, allProperties]);

  const handleDelete = (property) => {
    setSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setEditForm({
      direccion: property.direccion || '',
      publicacion: property.publicacion || '',
      archivos: property.archivos || []
    });
    setShowEditModal(true);
  };

  const handleBajaSubmit = async () => {
    try {
      const updatedPrice = bajaReason === 'vendido' ? salePrice : '0';
      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        venta: updatedPrice,
        motivo: bajaReason
      });
      setAllProperties(allProperties.filter(prop => prop.id !== selectedProperty.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      if (editForm.archivos.length > 0) {
        for (const file of selectedProperty.archivos) {
          const imageRef = ref(storage, file.fileName);
          await deleteObject(imageRef);
        }
      }

      const newFileURLs = [];
      for (const file of editForm.archivos) {
        const imageRef = ref(storage, file.fileName);
        await uploadBytes(imageRef, file);
        const fileURL = await getDownloadURL(imageRef);
        newFileURLs.push({ fileName: file.fileName, fileURL });
      }

      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        direccion: editForm.direccion,
        publicacion: editForm.publicacion,
        archivos: newFileURLs
      });

      setAllProperties(allProperties.map(prop =>
        prop.id === selectedProperty.id ? { ...prop, ...editForm, archivos: newFileURLs } : prop
      ));
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setEditForm({
      ...editForm,
      archivos: files.map(file => ({
        fileName: file.name,
        fileURL: URL.createObjectURL(file)
      }))
    });
  };

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <p>Cargando propiedades...</p>;
  }

  return (
    <div>
      <h3>Lista de Propiedades</h3>

      <input
        type="text"
        className="inputMolis"
        placeholder="Buscar propiedades..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>Imagen</th>
            <th onClick={() => handleSort('direccion')}>Dirección</th>
            <th onClick={() => handleSort('publicacion')}>Publicación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentProperties.map(property => (
            <tr key={property.id}>
              <td>
                {property.archivos && property.archivos.length > 0 ? (
                  <img src={property.archivos[0].fileURL} alt="Imagen de la propiedad" className="thumbnail" />
                ) : (
                  <span>No image available</span>
                )}
              </td>
              <td>{property.direccion}</td>
              <td>{property.publicacion}</td>
              <td>
                <button onClick={() => handleEdit(property)}>Editar</button>
                <button onClick={() => handleDelete(property)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        propertiesPerPage={propertiesPerPage}
        totalProperties={filteredProperties.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Editar Propiedad</h4>
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(); }}>
              <label>Dirección:</label>
              <input
                type="text" className="form-control"
                value={editForm.direccion}
                onChange={(e) => setEditForm({ ...editForm, direccion: e.target.value })}
              />

              <label>Publicación:</label>
              <input
                type="text" className="form-control"
                value={editForm.publicacion}
                onChange={(e) => setEditForm({ ...editForm, publicacion: e.target.value })}
              />

              <label>Imágenes:</label>
              <input
                type="file" className="form-control"
                multiple
                onChange={handleFileChange}
              />
              <div>
                {editForm.archivos.map((file, index) => (
                  <img key={index} src={file.fileURL} alt={`Preview ${index}`} className="thumbnail" />
                ))}
              </div>

              <button type="submit">Guardar cambios</button>
              <button onClick={() => setShowEditModal(false)}>Cancelar</button>
            </form>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Baja de Propiedad</h4>
            <p>Motivo de baja:</p>
            <select value={bajaReason} onChange={(e) => setBajaReason(e.target.value)} className="form-control">
              <option value="">Seleccione una opción</option>
              <option value="retirado">Se retira del mercado</option>
              <option value="vendido">Se vendió</option>
            </select>
            {bajaReason === 'vendido' && (
              <div>
                <p>Precio de venta:</p>
                <input
                  type="number" className="form-control"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                />
              </div>
            )}
            <button onClick={handleBajaSubmit}>Confirmar baja</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ propertiesPerPage, totalProperties, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProperties / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AdminPropertiesIA;
