import React, { useEffect, useState } from 'react';
import { useAuth } from './context/AuthContext.js'; // Usar el contexto de autenticación
import { useNavigate } from 'react-router-dom';
import AdminProperties from './components/AdminProperties.js';
import AddProperty from './components/AddProperty.js';
import CrearInm from './components/CrearInm.js'; // Importar el nuevo componente
import InmobiliariasList from './components/InmobiliariasList.js'; // Componente para mostrar la lista de inmobiliarias
import './AdminPanel.css';

const AdminPanel = () => {
  const { currentUser, userRole } = useAuth();
  const [view, setView] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login'); // Redirige si no hay usuario autenticado
    } else if (userRole !== 'admin') {
      navigate('/'); // Redirige si el usuario no es admin
    }
  }, [currentUser, userRole, navigate]);

  // Muestra un indicador de carga mientras se verifica el rol
  if (!currentUser || userRole === null) {
    return <p>Cargando...</p>;
  }

  const handleViewChange = (viewName) => {
    setView(viewName);
  };

  return (
    <div className="admin-panel">
      <h2>Panel de Administración</h2>
      <div className="admin-buttons">
        <button onClick={() => handleViewChange('users')} style={{ margin: '10px' }}>
          Usuarios
        </button>
        <button onClick={() => handleViewChange('properties')} style={{ margin: '10px' }}>
          Propiedades
        </button>
        <button onClick={() => handleViewChange('inmobiliarias')} style={{ margin: '10px' }}>
          Inmobiliarias
        </button>
      </div>
      {view === 'properties' && (
        <div className="admin-buttons">
          <button onClick={() => handleViewChange('properties-list')} style={{ margin: '10px' }}>
            Ver Propiedades
          </button>
          <button onClick={() => handleViewChange('add-property')} style={{ margin: '10px' }}>
            Cargar Nueva Propiedad
          </button>
        </div>
      )}
      {view === 'properties-list' && <AdminProperties />}
      {view === 'add-property' && <AddProperty />}
      {view === 'inmobiliarias' && (
        <div>
          <InmobiliariasList /> {/* Mostrar la lista de inmobiliarias */}
          <CrearInm /> {/* Mostrar el formulario para crear una nueva inmobiliaria */}
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
