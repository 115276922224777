import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import { useAuth } from './context/AuthContext.js';
import Menu from './components/Menu.js';
import Loading from './components/Loading.js';
import './Property.css'; // Asegúrate de tener este archivo CSS para estilos personalizados.

const Property = () => {
  const { propertyId } = useParams();
  const location = useLocation();
  const [propertyData, setPropertyData] = useState(null);
  const { currentUser } = useAuth();
  const firestore = getFirestore();
  const [consulta, setConsulta] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const referrer = new URLSearchParams(location.search).get('referrer');
  
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const docRef = doc(firestore, 'properties', propertyId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setPropertyData(docSnap.data());
        } else {
          console.log('No property found');
        }
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };
    
    fetchPropertyData();
  }, [firestore, propertyId]);
  
  useEffect(() => {
    if (currentUser && referrer) {
      console.log(`User referred by: ${referrer}`);
    }
  }, [currentUser, referrer]);

  if (!propertyData) {
    return  <div id="carga" className="containerLoading"><Loading /></div>;
  }

  const archivos = propertyData?.archivos || [];
  
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % archivos.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + archivos.length) % archivos.length);
  };

  const handleConsultaChange = (e) => {
    setConsulta(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('Debes estar logueado para enviar una consulta.');
      return;
    }

    if (!consulta.trim()) {
      alert('La consulta no puede estar vacía.');
      return;
    }

    try {
      await addDoc(collection(db, 'consultas'), {
        idPropiedad: propertyData.id,
        tipoPropiedad: propertyData.tipo || 'Tipo no disponible',
        direccion: propertyData.direccion || 'Dirección no disponible',
        barrio: propertyData.barrio || 'Barrio no disponible',
        ciudad: propertyData.ciudad || 'Ciudad no disponible',
        nombreUsuario: currentUser.displayName || 'Desconocido',
        telefono: currentUser.phoneNumber || 'No disponible',
        email: currentUser.email || 'No disponible',
        consulta,
        codigoReferido: currentUser?.referido || 'No disponible',
        createdAt: Timestamp.now(),
      });

      const mensaje = `Consulta sobre la propiedad: ${propertyData.direccion}, ${propertyData.barrio}, ${propertyData.ciudad}: ${consulta}`;
      const numeroWhatsApp = '5492233390092';
      window.open(`https://wa.me/${numeroWhatsApp}?text=${encodeURIComponent(mensaje)}`, '_blank');

      setConsulta('');
      alert('Consulta enviada exitosamente.');
    } catch (error) {
      console.error('Error al enviar la consulta: ', error);
      alert('Hubo un error al enviar la consulta.');
    }
  };

  // Mostrar los detalles booleanos que están en true
  const detallesBooleanos = [
    { label: 'Cochera', value: propertyData.cochera },
    { label: 'Baulera', value: propertyData.baulera },
    { label: 'Financia', value: propertyData.financia },
    { label: 'Vista al mar', value: propertyData.vista },
    { label: 'Acepta Permuta', value: propertyData.permuta },
    { label: 'Dependencia', value: propertyData.dep },
  ].filter(detalle => detalle.value);

  return (
    <div className="property-container">
      <Menu />
      <div className="vp-main-content">
        <div className="title-property">
            <h1>{`${propertyData.tipo} de ${propertyData.amb} ambientes en ${propertyData.barrio} - USD ${propertyData.publicacion}`}</h1>
        </div>
        <div className="contenedor-propiedad">
          {/* Slider de imágenes */}
          <div className="carousel">
            <div className="carousel-inner">
              {archivos.map((img, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                  style={{ transform: `rotateY(${index === currentIndex ? '0deg' : '90deg'})` }}
                >
                  <img src={img.fileURL} className="d-block w-100 imgprop" alt={`${propertyData.tipo} en ${propertyData.barrio}`} />
                </div>
              ))}
            </div>
            <i className="fa-solid fa-chevron-left carousel-control-prev" onClick={prevSlide}></i>
            <i className="fa-solid fa-chevron-right carousel-control-next" onClick={nextSlide}></i>
          </div>

          {/* Título de la propiedad */}
          

          {/* Detalles Booleanos */}
          {detallesBooleanos.length > 0 && (
            <ul className="detalles-booleanos">
              {detallesBooleanos.map((detalle, index) => (
                <li key={index} className="detalle-item">{detalle.label}</li>
              ))}
            </ul>
          )}
          {/* GENERAL*/}
          <ul className="lista-detalles">
            <li className="form-control"><i class="fa-solid fa-map-location-dot"></i> {propertyData.direccion}, {propertyData.barrio}, {propertyData.ciudad}</li>
            <li className="form-control"><i class="fa-solid fa-house-circle-check"></i> {propertyData.estado}</li>
            <li className="form-control"><i class="fa-solid fa-ruler"></i> Cubiertos: {propertyData.mcub}</li>
            <li className="form-control"><i class="fa-solid fa-ruler"></i> Descubiertos: {propertyData.mdes}</li>
          </ul>

          {/* Descripción */}
          <div className="descripcion-propiedad">
            <h3>Descripción</h3>
            <p>{propertyData.descrip || 'Descripción no disponible.'}</p>
          </div>

          {/* Formulario de consulta */}
          <div className="contenerdor-consulta-prop-ind">
            <form className="input-group input-consul" onSubmit={handleSubmit}>
              <input
                type="text"
                className="form-control input-bus-real"
                placeholder="Haz una consulta privada"
                value={consulta}
                onChange={handleConsultaChange}
              />
              <button type="submit" className="btn moli-o">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Property;
