import React from 'react';
import './VentasPage.css';
import CarouselPromos from './components/CarouselPromos.js';
import Segmentos from './components/Segmentos.js';
import Geolocalizacion from './components/Geolocalizacion.js';
import FiltroVentas from './components/PropertyFilter.js';
import Categorias from './components/Categorias.js';
import Menu from './components/Menu.js';

const VentasPage = () => (
  <div className="vp-container">
    <div className="vp-main-content">
      <CarouselPromos />
      <Segmentos />
      <Geolocalizacion />
      <Categorias />
      <FiltroVentas />
    </div>
    <Menu />
  </div>
);

export default VentasPage;
