import React, { useState, useEffect } from 'react';
import { collection, doc, query, where, getDocs, updateDoc, getDoc } from 'firebase/firestore'; 
import { db } from '../firebaseConfig.js';
import AddProperty from './AddProperty.js';
import { useAuth } from '../context/AuthContext.js';
import AddClient from './AddClient.js';
import ClientTable from './ClientTable.js';
import CreateDocument from './DigitalDocumentCreator.js';
import Settings from './Settings.js';
import AdminPropertiesIA from './AdminPropertiesIA.js';
import './Office.css';

const OfficeInm = () => {
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [showForm, setShowForm] = useState(false); 
  const [activeSection, setActiveSection] = useState(''); 
  const [assessors, setAssessors] = useState([]); 
  const [approvedAssessors, setApprovedAssessors] = useState([]);
  const { currentUser } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [inmobiliaria, setInmobiliaria] = useState(null);
  const [registro, setRegistro] = useState('');

  useEffect(() => {
    const fetchUserRoleAndInmobiliaria = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            setUserRole(userDoc.data().role);

            const inmobiliariaQuery = query(
              collection(db, 'inmobiliarias'),
              where('usuarioId', '==', currentUser.uid)
            );
            const inmobiliariaSnapshot = await getDocs(inmobiliariaQuery);

            if (!inmobiliariaSnapshot.empty) {
              const inmobiliariaDoc = inmobiliariaSnapshot.docs[0];
              setInmobiliaria(inmobiliariaDoc.id);
              setRegistro(inmobiliariaDoc.data().registro);
            } else {
              console.log('No se encontró la inmobiliaria para este usuario.');
            }
          } else {
            console.log('No se encontró el documento del usuario.');
          }
        } catch (error) {
          console.error('Error fetching user role or inmobiliaria:', error);
        }
      }
    };

    fetchUserRoleAndInmobiliaria();
  }, [currentUser]);

  useEffect(() => {
    const fetchProperties = async () => {
      if (inmobiliaria) {
        try {
          const propertiesQuery = query(
            collection(db, 'properties'),
            where('inm', '==', inmobiliaria)
          );
          const querySnapshot = await getDocs(propertiesQuery);

          const filteredProperties = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          setProperties(filteredProperties);
        } catch (error) {
          console.error('Error fetching properties:', error);
        }
      }
    };

    fetchProperties();
  }, [inmobiliaria]);

  useEffect(() => {
    const fetchAssessors = async () => {
      if (inmobiliaria) {
        const qSolicitudes = query(
          collection(db, 'solicitudesAs'),
          where('inmobiliaria', '==', inmobiliaria)
        );
        const querySnapshotSolicitudes = await getDocs(qSolicitudes);
        const solicitudesData = await Promise.all(
          querySnapshotSolicitudes.docs.map(async (solicitudDoc) => {
            const solicitudData = solicitudDoc.data();
            const userId = solicitudData.userId;

            const userDoc = await getDoc(doc(db, 'users', userId));
            const userData = userDoc.exists() ? userDoc.data() : null;

            return {
              id: solicitudDoc.id,
              ...solicitudData,
              nombre: userData ? userData.fullName : 'Desconocido',
            };
          })
        );

        setAssessors(solicitudesData.filter(solicitud => solicitud.status === 'pending'));
        const approvedData = solicitudesData.filter(solicitud => solicitud.status === 'approved');
        setApprovedAssessors(approvedData);
        await fetchCountsForApprovedAssessors(approvedData);
      }
    };

    fetchAssessors();
  }, [inmobiliaria]);

  const fetchCountsForApprovedAssessors = async (approvedData) => {
    const updatedApprovedAssessors = await Promise.all(approvedData.map(async (assessor) => {
      const userId = assessor.userId;
      const [clientsCount, propertiesCount, documentsCount] = await Promise.all([
        countDocuments('clientes', 'creadoPor', userId),
        countDocuments('properties', 'idUsuario', userId),
        countDocuments('documents', 'createdBy', userId)
      ]);

      return {
        ...assessor,
        clientesCount: clientsCount,
        propiedadesCount: propertiesCount,
        documentosCount: documentsCount
      };
    }));

    setApprovedAssessors(updatedApprovedAssessors);
  };

  const countDocuments = async (collectionName, fieldName, value) => {
    const q = query(
      collection(db, collectionName),
      where(fieldName, '==', value)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const handleAcceptAssessor = async (assessorId) => {
    try {
      const solicitudRef = doc(db, 'solicitudesAs', assessorId);
      await updateDoc(solicitudRef, { status: 'approved' });

      const solicitudDoc = await getDoc(solicitudRef);
      const userId = solicitudDoc.data().userId;

      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { 
        role: 'asesor', 
        inmobiliaria: inmobiliaria, 
      });

      setAssessors(prev => prev.filter(a => a.id !== assessorId));
    } catch (error) {
      console.error("Error al aceptar al asesor: ", error);
    }
  };
  
  const handleRejectAssessor = async (assessorId) => {
    try {
      const solicitudRef = doc(db, 'solicitudesAs', assessorId);
      await updateDoc(solicitudRef, { status: 'rejected' });
      setAssessors(prev => prev.filter(a => a.id !== assessorId));
    } catch (error) {
      console.error("Error al rechazar al asesor: ", error);
    }
  };
  
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
    <div className="office-container">
      <div className="section-container">
        <div className="section" onClick={() => handleSectionClick('clientes')}>
          <h3>Prospectos</h3>
        </div>
        <div className="section" onClick={() => handleSectionClick('propiedades')}>
          <h3>Propiedades</h3>
        </div>
        <div className="section" onClick={() => handleSectionClick('documentos')}>
          <h3>Documentos</h3>
        </div>
        <div className="section" onClick={() => handleSectionClick('asesores')}>
          <h3>Asesores</h3>
        </div>
        <div className="section" onClick={() => handleSectionClick('configuracion')}>
          <h3>Configuración</h3>
        </div>
      </div>

      {activeSection === 'clientes' && (
        <>
          <button className="btn btn-primary" onClick={() => setShowForm(false)}>
            Ver Prospectos
          </button>
          <button className="btn btn-secondary" onClick={toggleForm}>
            {showForm ? 'Cancelar' : 'Agregar Prospecto'}
          </button>
          {showForm ? <AddClient /> : <ClientTable clients={clients} />}
        </>
      )}

      {activeSection === 'propiedades' && (
        <>
          <button className="btn btn-primary" onClick={() => setShowForm(false)}>
            Ver Propiedades
          </button>
          <button className="btn btn-secondary" onClick={toggleForm}>
            {showForm ? 'Cancelar' : 'Agregar Propiedad'}
          </button>
          {showForm ? <AddProperty inmobiliaria={inmobiliaria} /> : <AdminPropertiesIA properties={properties} />}
        </>
      )}

      {activeSection === 'documentos' && (
        <CreateDocument />
      )}

      {activeSection === 'asesores' && (
        <div className="asesores-container">
          <h3>Asesores Pendientes</h3>
          {assessors.map(assessor => (
            <div key={assessor.id}>
              <p>{assessor.nombre}</p>
              <button onClick={() => handleAcceptAssessor(assessor.id)}>Aceptar</button>
              <button onClick={() => handleRejectAssessor(assessor.id)}>Rechazar</button>
            </div>
          ))}

          <h3>Asesores Aprobados</h3>
          {approvedAssessors.map(assessor => (
            <div key={assessor.id}>
              <p>{assessor.nombre} - Clientes: {assessor.clientesCount} - Propiedades: {assessor.propiedadesCount} - Documentos: {assessor.documentosCount}</p>
            </div>
          ))}
        </div>
      )}

      {activeSection === 'configuracion' && (
        <Settings inmobiliariaId={inmobiliaria} />
      )} 
    </div>
    </>
  );
};

export default OfficeInm;
